import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ModulesTable from './ModulesTable';
import AddOns from './Addons';
import PricingSummary from './PricingSummary';

const PricingCalculator = () => {
  const [selectedTier, setSelectedTier] = useState('team');
  const [previousTier, setPreviousTier] = useState('team');
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [userCounts, setUserCounts] = useState({});
  const [enterpriseSeats, setEnterpriseSeats] = useState(25);
  const [dataSources, setDataSources] = useState(0);
  const [yearTerm, setYearTerm] = useState(1);

  const basePricing = {
    team: 15000,
    business: 35000,
    enterprise: 70000
  };

  const modulesData = {
    fundraising: {
      name: 'AltviaOne for Fundraising',
      features: [
        // Marketing & Campaigns
        { name: 'Campaign Management', team: 'Basic', business: 'Smart List Manager', enterprise: 'Custom' },
        { name: 'Marketing Communication', team: 'Basic', business: 'Enhanced', enterprise: 'Custom' },
        { name: 'Event Planning', team: 'Basic', business: 'Enhanced', enterprise: 'Custom' },
        
        // Digital Investor Experience
        { name: 'Workspace Environment', team: '1', business: 'Multiple', enterprise: 'Unlimited' },
        { name: 'Landing Pages', team: 'None', business: true, enterprise: 'Unlimited' },
        { name: 'Data Room', team: 'Basic', business: 'Enhanced', enterprise: 'Custom' },
        
        // Pipeline & Conversion 
        { name: 'Pipeline Management', team: 'Basic', business: 'Enhanced', enterprise: 'Custom' },
        { name: 'Investor Tracking', team: 'Basic', business: 'Enhanced', enterprise: 'Custom' },
        { name: 'Conversion Analytics', team: 'Basic', business: 'Enhanced', enterprise: 'Custom' },
        
        // Platform Capabilities
        { name: 'Reports & Insights', team: 'Basic', business: 'Enhanced', enterprise: 'Custom' },
        { name: 'Custom Objects', team: 'None', business: 'Limited', enterprise: 'Unlimited' },
        { name: 'Data Onboarding', team: 'Ascent', business: 'Ascent + Sherpa', enterprise: 'Ascent + Sherpa' },
        
        // Support
        { name: 'Support Level', team: 'Portal', business: 'CSM', enterprise: 'CSM' },
        { name: 'Technical Account Manager', team: 'None', business: 'Optional', enterprise: 'Required' }
      ],
      pricePerUser: { team: 950, business: 1125, enterprise: 1350 }
    },
    irOps: {
      name: 'AltviaOne for IR Ops',
      features: [
        { name: 'IC', team: true, business: true, enterprise: true },
        { name: 'Workspaces', team: '1', business: true, enterprise: 'Unlimited' },
        { name: 'Landing Pages', team: 'None', business: true, enterprise: 'Unlimited' },
        { name: 'Cases', team: true, business: true, enterprise: 'Custom' },
        { name: 'Reports', team: true, business: true, enterprise: 'Custom' },
        { name: 'Insights', team: true, business: true, enterprise: 'Custom' },
        { name: 'Answers Designer', team: 'None', business: 'Optional', enterprise: true },
        { name: 'Custom Widgets', team: 'None', business: 'None', enterprise: true },
        { name: 'Data Onboarding', team: 'Ascent', business: 'Ascent + Sherpa', enterprise: 'Ascent + Sherpa' },
        { name: 'Support', team: 'Portal', business: 'CSM', enterprise: 'CSM' },
        { name: 'TAM', team: 'Optional', business: 'Optional', enterprise: 'Yes' }
      ],
      pricePerUser: { team: 1000, business: 1500, enterprise: 1875 }
    },
    dealTeam: {
      name: 'AltviaOne for Deal Team',
      features: [
        { name: 'Research Assistants', team: true, business: true, enterprise: true },
        { name: 'Workspaces', team: 'None', business: '1', enterprise: 'Unlimited' },
        { name: 'Landing Pages', team: 'None', business: '1', enterprise: 'Unlimited' },
        { name: 'Reports', team: 'Basics', business: 'More', enterprise: 'Custom' },
        { name: 'Insights', team: 'Basics', business: 'More', enterprise: 'Custom' },
        { name: 'Answers Designer', team: 'None', business: 'None', enterprise: '1' },
        { name: 'Custom Objects', team: 'None', business: 'More', enterprise: 'Unlimited' },
        { name: 'Data Onboarding', team: 'Ascent', business: 'Ascent + Sherpa', enterprise: 'Ascent + Sherpa' },
        { name: 'Support', team: 'Portal', business: 'CSM', enterprise: 'CSM' },
        { name: 'TAM', team: 'Optional', business: 'Optional', enterprise: 'Yes' }
      ],
      pricePerUser: { team: 1000, business: 1500, enterprise: 2200 }
    },
    portfolioManagement: {
      name: 'AltviaOne for Portfolio Management',
      features: [
        { name: 'Workspaces', team: '1', business: '1', enterprise: 'Unlimited' },
        { name: 'Landing Pages', team: 'None', business: '1', enterprise: 'Unlimited' },
        { name: 'Reports', team: 'Basics', business: 'More', enterprise: 'Custom' },
        { name: 'Insights', team: 'Basics', business: 'More', enterprise: 'Custom' },
        { name: 'Answers Designer', team: 'None', business: 'None', enterprise: '1' },
        { name: 'Custom Objects', team: 'None', business: 'More', enterprise: 'Unlimited' },
        { name: 'Data Onboarding', team: 'Ascent', business: 'Ascent + Sherpa', enterprise: 'Ascent + Sherpa' },
        { name: 'Support', team: 'Portal', business: 'CSM', enterprise: 'CSM' },
        { name: 'TAM', team: 'Optional', business: 'Optional', enterprise: 'Yes' }
      ],
      pricePerUser: { team: 1750, business: 2100, enterprise: 2450 }
    }
  };

  const addOnsData = {
    email: {
      name: 'Ascent for Email',
      description: 'Enhanced email capabilities with Enhanced tracking and automation',
      availability: { team: true, business: true, enterprise: true },
      pricePerUser: { team: 300, business: 300, enterprise: 300 }
    },
    aime: {
      name: 'AIMe AI Assistant',
      description: 'AI-powered insights and automation tools',
      availability: { team: false, business: true, enterprise: true },
      pricePerUser: { team: null, business: 1200, enterprise: 1200 }
    },
    data: {
      name: 'AltviaOne for Data',
      description: 'Data source integration and management',
      availability: { team: true, business: true, enterprise: true },
      pricePerSource: 5000
    },
    enhancements: {
      name: 'Data Enhancements',
      description: 'Token-based data processing and enrichment',
      availability: { team: true, business: true, enterprise: true },
      price: { 
        team: { tokens: '10K', price: 12000 },
        business: { tokens: '50K', price: 30000 },
        enterprise: { tokens: '250K', price: 90000 }
      }
    }
  };

  const getEnterpriseUserPrice = (seats) => {
    if (seats < 25) return 4000;
    if (seats < 50) return 4000;
    if (seats < 100) return 3500;
    if (seats < 150) return 3000;
    if (seats < 200) return 2100;
    if (seats < 250) return 1800;
    return 1500;
  };

  useEffect(() => {
    if (selectedTier === 'enterprise') {
      setSelectedModules(Object.keys(modulesData));
      setEnterpriseSeats(25);
    } else if (previousTier === 'enterprise') {
      // Only clear selections when coming FROM enterprise
      setSelectedModules([]);
      setUserCounts({});
    }
    // Otherwise, keep selections and counts when switching between team/business
  }, [selectedTier]);

  const getVolumeDiscount = (moduleCount) => {
    if (selectedTier === 'enterprise') return 1; // No volume discount for enterprise
    switch(moduleCount) {
      case 2: return 0.85; // 15% off
      case 3: return 0.75; // 25% off
      case 4: return 0.65; // 35% off
      default: return 1;
    }
  };

  const getYearDiscount = (years) => {
    switch(years) {
      case 2: return 0.95; // 5% off
      case 3: return 0.90; // 10% off
      default: return 1;
    }
  };

  const calculateModuleCosts = () => {
    let moduleTotal = basePricing[selectedTier];
    
    if (selectedTier === 'enterprise') {
      const perSeatPrice = getEnterpriseUserPrice(enterpriseSeats);
      moduleTotal += enterpriseSeats * perSeatPrice;
      return moduleTotal;
    }

    selectedModules.forEach(moduleId => {
      const userCount = userCounts[moduleId] || 0;
      const pricePerUser = modulesData[moduleId].pricePerUser[selectedTier];
      moduleTotal += userCount * pricePerUser;
    });

    const discountableAmount = moduleTotal - basePricing[selectedTier];
    const discountedAmount = discountableAmount * getVolumeDiscount(selectedModules.length);
    return basePricing[selectedTier] + discountedAmount;
  };

  const calculateAddOnCosts = () => {
    let total = 0;
    selectedAddOns.forEach(addonId => {
      const addon = addOnsData[addonId];
      if (addon.pricePerUser) {
        const userCount = selectedTier === 'enterprise' ? enterpriseSeats : (userCounts[addonId] || 0);
        total += userCount * addon.pricePerUser[selectedTier];
      } else if (addonId === 'data') {
        total += dataSources * addon.pricePerSource;
      } else if (addonId === 'enhancements') {
        total += addon.price[selectedTier].price;
      }
    });
    return total;
  };

  const calculateTotalCost = () => {
    const annualCost = calculateModuleCosts() + calculateAddOnCosts();
    return annualCost * yearTerm * getYearDiscount(yearTerm);
  };

  const handleModuleSelect = (moduleId, checked) => {
    if (selectedTier === 'enterprise') return;
    
    setSelectedModules(prev =>
      checked
        ? [...prev, moduleId]
        : prev.filter(m => m !== moduleId)
    );
    if (!checked) {
      setUserCounts(prev => {
        const newCounts = { ...prev };
        delete newCounts[moduleId];
        return newCounts;
      });
    }
  };

  const handleAddOnSelect = (addonId, checked) => {
    setSelectedAddOns(prev =>
      checked
        ? [...prev, addonId]
        : prev.filter(a => a !== addonId)
    );
    if (!checked) {
      setUserCounts(prev => {
        const newCounts = { ...prev };
        delete newCounts[addonId];
        return newCounts;
      });
    }
  };

  const handleUserCountChange = (id, count) => {
    setUserCounts(prev => ({
      ...prev,
      [id]: count
    }));
  };

  const handleTierChange = (newTier) => {
    setPreviousTier(selectedTier);
    setSelectedTier(newTier);
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      gap: 1,
      maxHeight: 'calc(100vh - 100px)',
      overflow: 'hidden'
    }}>
      <Box sx={{ flex: 1, overflow: 'auto', pr: 2 }}>
        <ModulesTable
          modules={modulesData}
          selectedTier={selectedTier}
          selectedModules={selectedModules}
          userCounts={userCounts}
          enterpriseSeats={enterpriseSeats}
          onModuleSelect={handleModuleSelect}
          onUserCountChange={handleUserCountChange}
          onEnterpriseSeatsChange={setEnterpriseSeats}
          onTierChange={handleTierChange}
        />

        <AddOns
          addOns={addOnsData}
          selectedTier={selectedTier}
          selectedAddOns={selectedAddOns}
          userCounts={userCounts}
          enterpriseSeats={enterpriseSeats}
          dataSources={dataSources}
          onAddOnSelect={handleAddOnSelect}
          onUserCountChange={handleUserCountChange}
          onDataSourcesChange={setDataSources}
        />
      </Box>

      <Box sx={{ width: 350, height: '100%', overflow: 'auto' }}>
        <PricingSummary
          selectedTier={selectedTier}
          selectedModules={selectedModules}
          selectedAddOns={selectedAddOns}
          moduleCosts={calculateModuleCosts()}
          addOnCosts={calculateAddOnCosts()}
          volumeDiscount={(1 - getVolumeDiscount(selectedModules.length)) * 100}
          yearTerm={yearTerm}
          onYearTermChange={setYearTerm}
          totalCost={calculateTotalCost()}
          yearDiscount={(1 - getYearDiscount(yearTerm)) * 100}
          enterpriseSeats={enterpriseSeats}
          perSeatPrice={selectedTier === 'enterprise' ? getEnterpriseUserPrice(enterpriseSeats) : null}
        />
      </Box>
    </Box>
  );
};

export default PricingCalculator;