import { jwtDecode } from "jwt-decode";
import { redirect } from "react-router";

const apiBaseUrl = process.env.REACT_APP_API_URL

export const apiFetch = async ({endpoint, method, payload, params}) => {
  try {
    let url = `${apiBaseUrl}${endpoint}`;
    
    // Use browser-standard URLSearchParams API to add query params to URL
    if (params) {
      const searchParams = new URLSearchParams(params);

      // add ? if it isn't there yet (endpoint might already have params)
      if (!url.includes('?')) {
        url += '?';
      }

      url += `${searchParams}`;
    }

    const resp = await fetch(url, {
      method: method,
      headers: {'Content-type': 'application/json'},
      body: JSON.stringify(payload)
    });

    if (resp.status == 204) {
      return;
    } else if (resp.ok) {
      const respBody = await resp.json();
      return respBody;
    } else {
      throw new Error(`Call to ${url} returned unsuccesful response: ${resp.status}`);
    }
  } catch (error) {
    throw new Error(`Call failed with error: ${error}`);
  }
};

export const needToLogIn = () => {
  // Get Cognito ID token and user data from session storage
  const token = sessionStorage.getItem("idToken");
  const user = sessionStorage.getItem("user");

  // If token and/or user is missing, return true
  return !(token && user)
}

// higher-order function for consistent error handling across data loaders;
// takes async function as argument, returns async function
export const loaderWrapper = (loader) => {
  return async (...props) => {
    // redirect if no authed user session
    if (needToLogIn()) return redirect("/login");

    try {
      const data = await loader(...props);
      return data;
    } catch (e) {
      // redirect to login if session ended as result of failure to refresh token,
      // otherwise re-throw error
      if (needToLogIn()) {
        console.error("Error in loader, redirecting to login:", e.message);
        return redirect("/login");
      } else {
        throw e;
      }
    } 
  }
}

// returns valid Cognito idToken; either current if still valid or refreshed one
const getRefreshedToken = async () => {
  // get current ID token from secure storage (see AuthCallback for origin)
  const currentToken = sessionStorage.getItem("idToken");
  // decode JWT (does not validate JWT signature, implicitly trusting for this)
  const decodedToken = jwtDecode(currentToken);
  // get expiration claim from ID token (seconds since epoch UTC)
  const expiresSeconds = decodedToken.exp;
  // subtract 2 minutes to advance expiration time & force pre-emptive refresh
  const bufferedExpiresSeconds = expiresSeconds - 120
  // convert to milliseconds for 1:1 comparison
  const expiresMs = bufferedExpiresSeconds * 1000;
  // get current UTC time in ms
  const currentMs = Date.now();

  // if expires time is in the past, refresh token, otherwise return current.
  if (expiresMs < currentMs) {
    console.log('Session expired. Refreshing...')
    const newToken = await refreshToken();
    console.log('Refreshed session.')
    return newToken;
  } else {
    return currentToken;
  }
}

// returns refreshed idToken
const refreshToken = async () => {
  // get current refresh token
  const refreshToken = sessionStorage.getItem('refreshToken');
  const email = sessionStorage.getItem('email');

  try {
    const response = await apiFetch({
      endpoint: '/auth/refresh',
      method: 'POST',
      payload: { refresh_token: refreshToken, email: email }
    });
    
    // update tokens stored in session
    storeTokensInSession(response.tokens);

    // return new id token
    return response.tokens.id_token;
  } catch (error) {
    // Clear session (so user can log in and get valid refresh token)
    sessionStorage.clear();

    // re-throw error to halt execution
    throw error;
  }
};

export async function signOut() {
  try {
    await apiFetch({
      endpoint: '/auth/revoke',
      method: 'POST',
      payload: {
        refresh_token: sessionStorage.getItem('refreshToken'),
        email: sessionStorage.getItem('email')
      }
    });
  } catch (error) {
    // Error is intentionally suppressed to always allow user to get to login
    console.error("Failed to revoke user session. Error:", error.message)
  }
  sessionStorage.clear();
}

// Store whichever supported values are in token object in session storage
export function storeTokensInSession(tokens) {
  tokens.access_token && sessionStorage.setItem('accessToken', tokens.access_token);
  tokens.id_token && sessionStorage.setItem('idToken', tokens.id_token);
  tokens.refresh_token && sessionStorage.setItem('refreshToken', tokens.refresh_token);
  tokens.expires_in && sessionStorage.setItem('expiresIn', tokens.expires_in);
  tokens.token_type && sessionStorage.setItem('tokenType', tokens.token_type);
}

export const authedApiFetch = async ({endpoint, method, payload = null, returnResponse = false}) => {
  try {
    const token = await getRefreshedToken();

    const url = `${apiBaseUrl}${endpoint}`
    const resp = await fetch(url, {
      method: method,
      headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
      },
      body: payload ? JSON.stringify(payload) : null
    })

    // in some cases, like forms that need validation errors, return response
    if (returnResponse) {
      return resp;
    } else if (resp.status == 204) {
      return;
    } else if (resp.ok) {
      const respBody = await resp.json();
      return respBody;
    } else {
      throw new Error(`Call to ${url} returned unsuccessful response: ${resp.status}`);
    }
  } catch (error) {
    throw new Error(`Call failed with error: ${error}`);
  }
};