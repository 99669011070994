import { Box, Typography } from "@mui/material";
import Actions from "../../components/Actions";
import { Navigate, useOutletContext, useRevalidator } from "react-router";
import HandymanIcon from '@mui/icons-material/Handyman';
import CheckIcon from '@mui/icons-material/Check';
import { authedApiFetch } from "../../utils/Api";

const TenantAdmin = () => {
  // Retrieve data from parent route
  const { tenant, user } = useOutletContext();

  // For imperatively refreshing data from loaders
  const revalidator = useRevalidator();

  // handler passed to action component, called after confirming action
  const handleCreateUserPool = async () => {
    await authedApiFetch({
      endpoint: `/tenants/${tenant.id}/user_pool`,
      method: 'POST'
    });

    // imperatively revalidate b/c not using React Router action.
    // TODO: refactor so that this goes through a clientAction.
    revalidator.revalidate();
  }

  // Array of actions passed to Action component
  const actions = [
    {
      name: 'createUserPool',
      label: 'Create User Pool and App Clients',
      icon: <HandymanIcon/>,
      disabledIcon: <CheckIcon/>,
      confirmText: "This will create a user pool and app clients for the current tenant.",
      disabledCheck: (() => !!tenant.user_pool_id),
      actionHandler: handleCreateUserPool
    }
  ]

  // Route back to dashboard if non-admin user attempts to navigate directly
  if (user.role != 'altvia_admin') {
    return <Navigate to={`tenants/${tenant.id}/dashboard`}/>;
  } else {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", rowGap:"16px", height: "100vh" }}>
        <Typography variant='h4'>
          Tenant Admin
        </Typography>
        <Actions actions={actions}/>
      </Box>
    )    
  }
}

export default TenantAdmin;