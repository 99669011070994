import { Container, Typography, ThemeProvider, createTheme } from "@mui/material";
import PricingCalculator from "./PricingCalculator";

const pricingTheme = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
      light: "#E4EBEF",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

function Pricing() {
  return (
    <ThemeProvider theme={(theme) => createTheme({ ...theme, ...pricingTheme })}>
      <Container maxWidth='xl' sx={{ py: 1 }}>
        <Typography variant='h3' component='h1' gutterBottom align='center'>
          AltviaOne Platform Calculator
        </Typography>
        <PricingCalculator />
      </Container>
    </ThemeProvider>
  );
}

export default Pricing;