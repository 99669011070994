import { Outlet, useLoaderData, useOutletContext } from "react-router"
import Sidebar from "./Sidebar";
import { Box } from "@mui/material";
import { authedApiFetch } from "../utils/Api";

export const tenantLoader = async ({ params }) => {
  // Get tenant ID from URI
  const { tenantId } = params;

  return await authedApiFetch({ endpoint: `/tenants/${tenantId}`, method: 'GET'})
}

const Tenant = () => {
  const tenant = useLoaderData();
  const outletData = useOutletContext();

  return (
    <>
      <Sidebar tenant={tenant} user={outletData.user} />
      <Box component='main' sx={{ flexGrow: 1, p: 3, overflow: "auto" }}>
        <Outlet context={{tenant, ...outletData}}/>
      </Box>
    </>
  )
};

export default Tenant;