import { LoadingButton } from "@mui/lab";
import { ButtonGroup, Button, DialogTitle, Dialog, DialogContent, DialogContentText, DialogActions, Snackbar, Alert } from "@mui/material";

import { useState } from "react";

// Example action:
// {
//   name: 'createUserPool',
//   label: 'Create User Pool and App Clients',
//   icon: <HandymanIcon/>,
//   disabledIcon: <CheckIcon/>,
//   confirmText: "This will create a user pool and app clients for the current tenant.",
//   disabledCheck: (() => !!tenant.user_pool_id),
//   actionHandler: handleCreateUserPool
// }
const Actions = ({actions}) => {
  const [dialogState, setDialogState] = useState({});
  const [loadingState, setLoadingState] = useState({});

  // TODO: get from a global snackbar context
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState();

  // generic action handler; provide action object
  const handleAction = async (action) => {
    handleCloseDialogue(action.name);
    setLoadingState({...loadingState, [action.name]: true});

    try {
      await action.actionHandler();
    } catch (e) {
      setSnackbarContent({ message: e.message, severity: 'error' });
      setSnackbarOpen(true);
      setLoadingState({...loadingState, [action.name]: false});
    }
    setSnackbarContent({ message: 'Action successful', severity: 'success' });
    setSnackbarOpen(true);
    setLoadingState({...loadingState, [action.name]: false});
  };
  
  // Generic dialogue closer and opener; provide string representing action
  const handleCloseDialogue = (actionName) => {
    let dialogStateClone = {...dialogState};
    dialogStateClone[actionName] = false;
    setDialogState(dialogStateClone);
  }
  const handleOpenDialogue = (actionName) => {
    let dialogStateClone = {...dialogState};
    dialogStateClone[actionName] = true;
    setDialogState(dialogStateClone);
  }

  // Iterate over actions to create buttons
  const buttons = actions.map((action) => {
    return (
      <LoadingButton
        key={`${action.name}-button`}
        loading={loadingState[action.name]}
        disabled={action.disabledCheck()}
        startIcon={action.disabledCheck() && action.disabledIcon ? action.disabledIcon : action.icon}
        onClick={() => handleOpenDialogue(action.name)}
      >
        {action.label}
      </LoadingButton>            
    )
  })

  // Iterate over actions to create dialogue modals
  const dialogues = actions.map((action) => {
    return (
      <Dialog
        key={`${action.name}-dialogue`}
        open={dialogState[action.name] || false}
        onClose={handleCloseDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Action: {action.label}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {action.confirmText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialogue(action.name)}>Cancel</Button>
          <Button onClick={() => handleAction(action)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  })

  return (
    <>
      <ButtonGroup>
        {buttons}
      </ButtonGroup>
      {dialogues}
      {/* TODO: move this to a global snackbar component, access thru hook */}
      <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={()=> setSnackbarOpen(false)}
          severity={snackbarContent?.severity}
          variant="filled"
        >
          {snackbarContent?.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Actions;