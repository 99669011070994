// App.jsx
import React from "react";
import { Navigate, createBrowserRouter } from "react-router";
import { RouterProvider } from "react-router/dom";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import UDM from "./features/UDM/UDM";
import Ascent from "./features/UDM/Ascent";
import Launchpad, { dashboardLoader } from "./features/Dashboard/Dashboard";
import UserList, { loader as usersLoader } from "./features/Users/UserList";
import Login from "./components/Login";
import AuthCallback from "./components/AuthCallback";
import Root, { rootLoader } from "./components/Root";
import theme from './styles/theme';
import ErrorPage from "./components/ErrorPage";
import QSEmbeddedDashboard from "./features/AnswersEmbeddedAuthoring/QuicksightEmbeddedAuthoring";
import DataTables, { loader as dataTablesLoader } from './features/DataTables/DataTables'
import DataIndex from './features/DataTables/DataIndex'
import DataTable, { loader as dataTableLoader } from './features/DataTables/DataTable';
import DataTableRecords, { loader as dataTableRecordsLoader } from './features/DataTables/DataTableRecords';
import DataTableSettings, { action as dataTableSettingsAction } from './features/DataTables/DataTableSettings';
import DataTableIndex from './features/DataTables/DataTableIndex';
import DataTableNew, { action as dataTableNewAction } from './features/DataTables/DataTableNew';
import { approvalAction, recordUpdateAction, getPresignedUrlAction } from './features/DataTables/DataGridDrawer'
import Connections, { loader as connectionsLoader, action as createConnectionAction } from './features/Connections/Connections'
import ConnectionDetails, { updateAction as connectionUpdateAction } from './features/Connections/ConnectionDetails';
import ConnectionIndex from './features/Connections/ConnectionIndex'
import Tenant, { tenantLoader } from "./components/Tenant";
import RootIndex from "./components/RootIndex";
import Pricing from "./features/Pricing/Pricing";
import TenantAdmin from "./features/TenantAdmin/TenantAdmin";

// higher-order function used for consistent login redirect logic across loaders
import { loaderWrapper } from "./utils/Api";

const App = () => {
  const router = createBrowserRouter([
    { 
      path: '/login',
      element: <Login />
    },
    {
      path: '/auth/callback',
      element: <AuthCallback />,
    },
    {
      path: '/',
      element: <Root />,
      loader: loaderWrapper(rootLoader), // gets tenants; redirects to login if not authed
      errorElement: <ErrorPage/>,
      children: [
        {
          index: true,
          element: <RootIndex /> // navigates to first of user's tenants
        },
        {
          path: 'tenants/:tenantId',
          element: <Tenant />,
          loader: loaderWrapper(tenantLoader),
          children:
          [
            {
              index: true,
              path: 'dashboard',
              element: <Launchpad />,
              loader: loaderWrapper(dashboardLoader)
            },
            {
              path: 'pricing',
              element: <Pricing />
            },
            {
              path: 'udm',
              element: <UDM />
            },
            {
              path: 'ascent',
              element: <Ascent />
            },
            {
              path: 'users',
              element: <UserList />,
              loader: loaderWrapper(usersLoader)
            },
            {
              path: 'answersDashboard',
              element: <QSEmbeddedDashboard />
            },
            {
              path: "connections",
              element: <Connections />, // sidebar & outlet to main pane
              loader: loaderWrapper(connectionsLoader), // get connections for org
              action: createConnectionAction, // create Connection
              errorElement: <ErrorPage />,
              children: [
                {
                  index: true,
                  element: <ConnectionIndex /> // redirects to first connection
                },
                {
                  path: ":connectionId",
                  element: <ConnectionDetails />, // connections pane
                  children: [
                    {
                      path: "update",
                      action: connectionUpdateAction
                    }
                  ]
                }
              ]
            },
            {
              path: "data-tables",
              element: <DataTables/>,
              loader: loaderWrapper(dataTablesLoader),
              errorElement: <ErrorPage />,
              children: [
                {
                  index: true,
                  element: <DataIndex/>
                },
                {
                  path: "new",
                  element: <DataTableNew/>,
                  action: dataTableNewAction
                },
                {
                  path: ":dataTableId",
                  element: <DataTable/>,
                  loader: loaderWrapper(dataTableLoader),
                  children: [
                    {
                      index: true,
                      element: <DataTableIndex/>
                    },
                    {
                      path: "records",
                      element: <DataTableRecords/>,
                      loader: loaderWrapper(dataTableRecordsLoader),
                      children: [
                        {
                          path: ":recordId/update",
                          action: recordUpdateAction // defined on datagrid drawer
                        },
                        {
                          path: ":recordId/approve",
                          action: approvalAction // defined on datagrid drawer
                        },
                        {
                          path: ":recordId/presigned_url",
                          action: getPresignedUrlAction // defined on datagrid drawer
                        }
                      ]
                    },
                    {
                      path: "settings",
                      element: <DataTableSettings/>,
                      action: dataTableSettingsAction
                    }
                  ]
                }
              ]
            },
            {
              path: "admin",
              element: <TenantAdmin/>
            },
            {
              path: '*',
              element: <Navigate to='dashboard' />
            }
          ]
        }
      ]
    },
    {
      path: '*',
      element: <Navigate to='/' />
    }
  ]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <RouterProvider router={router} />
      </Box>
    </ThemeProvider>
  );
}

export default App;
