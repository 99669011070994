import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, Box, Checkbox } from "@mui/material";
import { useLoaderData, useOutletContext, useRevalidator } from "react-router";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { authedApiFetch } from "../../utils/Api";
import Actions from "../../components/Actions";

export const loader = async({ params }) => {
  // Get tenant ID from URI
  const { tenantId } = params;

  return authedApiFetch({ endpoint: `/tenants/${tenantId}/users`, method: 'GET'})
}

const UserList = () => {
  const [selected, setSelected] = useState([]);
  
  // Retrieve loader data
  const users = useLoaderData();

  // Retrieve current user to apply role permissions
  const { user } = useOutletContext();

  // For imperatively reloading the page data after completing actions
  const revalidator = useRevalidator();

  // Store currently selected users in React state when MUI grid reports selects
  const handleSelectedRows = (selectedRows) => {
    setSelected(selectedRows);
  }

  const roleMap = {
    "tenant_read_only": "Read Only",
    "tenant_admin": "Admin",
    "tenant_user": "User"
  }

  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: "first_name", headerName: "First name", minWidth: 130 },
    { field: "last_name", headerName: "Last name", minWidth: 130 },
    { field: "email", headerName: "Email", minWidth: 250 },
    { field: "phone", headerName: "Phone", minWidth: 150 },
    { field: "role", headerName: "Role", minWidth: 100, renderCell: (params) => roleMap[params.value] },
    { field: "auth_provider_user_id",
      headerName: "Altvia Login",
      minWidth: 130,
      align: 'center',
      renderCell: (params) => <Checkbox checked={params.value} disabled />,
    },
    {
      field: "aim",
      headerName: "AIM",
      minWidth: 90,
      align: 'center',
      renderCell: (params) => <Checkbox checked={params.value} disabled />,
    },
    {
      field: "share_secure",
      headerName: "ShareSecure",
      minWidth: 130,
      align: 'center',
      renderCell: (params) => <Checkbox checked={params.value} disabled />,
    },
    {
      field: "answers",
      headerName: "Answers",
      minWidth: 100,
      align: 'center',
      renderCell: (params) => <Checkbox checked={params.value} disabled />,
    },
  ];

  const handleCreateAltviaLogin = async () => {
    const selectedUsers = users.filter((user) => selected.includes(user.id));
    const selectedAndNeedLogin = selectedUsers.filter((user) => !user.auth_provider_user_id);

    await Promise.all(
      selectedAndNeedLogin.map((user) => {
        authedApiFetch({
          endpoint: `/users/${user.id}/auth_provider_user`,
          method: 'POST'
        })
      })
    );

    // imperatively revalidate b/c not using React Router action.
    // TODO: refactor so that this goes through a clientAction.
    revalidator.revalidate();
  }

  // Actions to dynamically create buttons and confirmation UI for.
  const actions = [
    {
      name: 'createAltviaLogin',
      label: 'Create Altvia Login',
      icon: <LockOpenIcon/>,
      confirmText: "This will create users in this tenant's user pool for the" +
        " selected users and send them an email containing their temporary" + 
        " password and link to Altvia One. Selected users who already have" + 
        " logins will be ignored.",
      disabledCheck: (() => selected.length == 0),
      actionHandler: handleCreateAltviaLogin
    }
  ]

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap:"16px", height: "100vh" }}>
      <Typography variant='h4'>
        Users
      </Typography>
      {(user.role == "altvia_admin" || user.role == "altvia_power_user") && 
        <Actions actions={actions}/>
      }
      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          checkboxSelection
          autosizeOnMount
          rows={users}
          columns={columns}
          pageSize={10}
          onRowSelectionModelChange={handleSelectedRows}
          />
      </Box>
    </Box>
  );
};

export default UserList;
