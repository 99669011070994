import React, { useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router";
import { apiFetch, storeTokensInSession } from "../utils/Api";

const AuthCallback = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const email = sessionStorage.getItem('email');
  const navigate = useNavigate();

  const processAuth = async () => {
    const code = searchParams.get('code');
    
    try {
      const response = await apiFetch({
        endpoint: '/auth/callback',
        method: 'GET',
        params: { code, email }
      });
      
      if (response.tokens) {
        storeTokensInSession(response.tokens)
        sessionStorage.setItem('user', JSON.stringify(response.user))
        navigate('/');
      } else {
        console.error("Unexpected response from server while authenticating");
        sessionStorage.setItem('email', null);
        navigate("/login");
      }
    } catch (error) {
      console.error("Authentication failed:", error);
      sessionStorage.setItem('email', null);
      navigate("/login");
    }
  }

  // doing what React docs say not to, to stop calling callback twice in effect
  const submitted = useRef(null);

  useEffect(() => {
    // Abuse ref to prevent callback getting called twice in development
    if (!submitted.current) {
      submitted.current = true;
      
      processAuth();
    }
  }, []);

  return <div>Authenticating...</div>;
};

export default AuthCallback;
